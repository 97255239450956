import React, { Component } from "react";
import StyleWrapper from "./StyleWrapper";
import HomeNavbar from "../HomeNavbar/HomeNavbar";
import HomeSignupModal from "../Modals/Signup";
import HomeLoginModal from "../Modals/Login";
import FooterNavbar from "../Footer/FooterNavbar";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Bridge from "../../../resources/images/bridge-image/Bridge.png";

import { Row, Col, Button, Form, Input, Switch, Upload } from "antd";

import { FormInput } from "../../../helpers/forms";

import espaceDebiteurActions from "../../../Redux/espaceBebiteur/actions";
import moment from "moment";
import { shortenFileName } from "../../../helpers/utils";
import history from "../../../helpers/history";
import { showError } from "../../../helpers/notificationHelper";

import { DeleteOutlined,CloseOutlined } from "@ant-design/icons";
import courrierLabels from "../../../helpers/courrierLabels";
import { colors } from "../../../helpers/appColors";

class ReferenceDossier extends Component {
  state = {
    fileName: "",
    file: null,
    switchState: [false, false, false, false],
    isSwitchChecked: false,
    switched: -1,
    items: [
      {
        label: "Approuvée",
        status: "approuvee",
        render: (data) => {
          return (
            <div>
              En approuvant la relance, vous vous engagez à adresser le paiement à votre créancier
              <Form.Item
                label="*statut"
                name="amount"
                hidden={true}
                //initialValue={data.mainBalance}
              >
                <Input />
              </Form.Item>
            </div>
          );
        },
      },
      {
        label: "Approuvée partiellement",
        status: "approuvee_partielle",
        render: (data) => {
          return (
            <div>
              <Row gutter={[8, 8]}>
                <Col span={7}>
                  <div className="text--black" style={{ marginTop: "10px" }}>
                    Montant approuvé
                    <span className="red-text"> * </span>
                  </div>
                </Col>
                <Col span={9}>
                  <FormInput
                    name={"amount"}
                    rules={[
                      {
                        required: true,
                        message: "Veuillez saisir le montant",
                      },
                    ]}
                    type="currency"
                    preserve = {true}
                  ></FormInput>
                </Col>
                <Col span={8}>
                  <div
                    className="text--black"
                    style={{ marginTop: "10px", marginLeft: "5px" }}
                  >
                    à régler au créancier
                  </div>
                </Col>
              </Row>
              <div className="raison-block-form">
                <div className="text--black" style={{ marginTop: "10px" }}>
                  Rédigez la raison de la retenue
                  <span className="red-text"> * </span>
                </div>
                <FormInput
                  rules={[
                    {
                      required: true,
                      message: "Veuillez saisir la raison",
                    },
                  ]}
                  type="textArea"
                  name={"raison"}
                ></FormInput>
              </div>

              <div className="raison-block-mail">
                <div
                  className="text--black"
                  style={{ marginTop: "10px", marginRight: "15px" }}
                >
                  Adresse mail
                  <span className="red-text"> * </span>
                </div>
                <div>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: `Veuillez saisir l'adresse mail`,
                      },
                      {
                        type: "mail",
                        message: "Veuillez saisir une adresse mail valide",
                      },
                    ]}
                    name={"email"}
                  >
                    <Input className="statut-mail__input" type="email"></Input>
                  </Form.Item>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        label: "Suspendue",
        status: "suspendue",
        render: (data) => {
          return (
            <div>
              <div className="raison-block-form">
                <div className="text--black" style={{ marginTop: "10px" }}>
                  Rédigez la raison de la retenue
                  <span className="red-text"> * </span>
                </div>
                <FormInput
                  rules={[
                    {
                      required: true,
                      message: "Veuillez saisir la raison",
                    },
                  ]}
                  type="textArea"
                  name={"raison"}
                ></FormInput>
              </div>

              <div className="raison-block-mail">
                <div
                  className="text--black"
                  style={{ marginTop: "10px", marginRight: "15px" }}
                >
                  Adresse mail
                  <span className="red-text"> * </span>
                </div>
                <div>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: `Veuillez saisir l'adresse mail`,
                      },
                      {
                        type: "mail",
                        message: "Veuillez saisir une adresse mail valide",
                      },
                    ]}
                    name={"email"}
                  >
                    <Input className="statut-mail__input" type="email"></Input>
                  </Form.Item>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        label: "Refusée",
        status: "refusee",
        render: (data) => {
          return (
            <div>
              <div className="raison-block-form">
                <div className="text--black" style={{ marginTop: "10px" }}>
                  Rédigez la raison de la retenue
                  <span className="red-text"> * </span>
                </div>
                <FormInput
                  rules={[
                    {
                      required: true,
                      message: "Veuillez saisir la raison",
                    },
                  ]}
                  type="textArea"
                  name={"raison"}
                ></FormInput>
              </div>
              <div className="text-remark text--black">
                Pour que vos contestations soient retenues, elles doivent être
                étayées, pièces à l'appui si nécessaire.
              </div>
              <div className="raison-block-file">
                <div
                  className="text--black raison-block-file__label"
                  style={{ marginTop: "10px", marginRight: "15px" }}
                >
                  <strong>Pièces</strong>
                </div>
                <div className="raison-block-file__input">
                  {!data.fileName && (
                    <Upload
                      accept={".pdf"}
                      onChange={(event) => {
                        data.handleUpload(event);
                      }}
                      fileList={[]}
                      maxCount={1}
                    >
                      <Button
                        className="raison-block-file__upload-btn"
                        size="middle"
                      >
                        <strong>Parcourir...</strong>
                      </Button>
                    </Upload>
                  )}
                  <div>
                    {data.fileName && (
                      <>
                        <span className="name-file">
                          {shortenFileName(data.fileName, 20)}
                        </span>
                        <Button
                          className="remove-btn-file"
                          onClick={() => {
                            data.handleRemove();
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="raison-block-mail">
                <div
                  className="text--black raison-block-mail__label"
                  style={{ marginTop: "10px", marginRight: "15px" }}
                >
                  Adresse mail
                  <span className="red-text"> * </span>
                </div>
                <div className="raison-block-mail__input">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: `Veuillez saisir l'adresse mail`,
                      },
                      {
                        type: "mail",
                        message: "Veuillez saisir une adresse mail valide",
                      },
                    ]}
                    name={"email"}
                  >
                    <Input className="statut-mail__input" type="email"></Input>
                  </Form.Item>
                </div>
              </div>
            </div>
          );
        },
      },
    ],
  };

  optionRef = React.createRef();

  componentDidMount() {
    const { reminder } = this.props.espaceDebiteur;
    if (!reminder) history.push("/debiteur");
  }

  onOptionsFinish = (values) => {
    const { isSwitchChecked, items, switched, file , switchState } = this.state;
    const { reminder } = this.props.espaceDebiteur;
    if (isSwitchChecked) {

      const indexOfSwitched =  [...switchState].indexOf(true);

      const data = {
        ...values,
        reference: reminder.reference,
        orderId: reminder.id,
        status: items[indexOfSwitched].status,
      };

      this.props.reqComputeStatus({ data: data, file: file });
    } else {
      showError("REQUIRED_OPTIONS");
    }
  };

  // fonction pour gérer le clic sur un commutateur
  handleSwitchClick = (index) => {
    const { switchState } = this.state;

    const newSwitchState = [...switchState];
    newSwitchState[index] = !newSwitchState[index];

    for (let i = 0; i < newSwitchState.length; i++) {
      if (i !== index && newSwitchState[i]) {
        newSwitchState[i] = false;
      }
    }

    const checked = newSwitchState.some((state) => state === true);

    this.setState({
      switchState: newSwitchState,
      isSwitchChecked: checked,
      amountValue: ""
    });
  };

  handleUpload = (event) => {
    const file = event.fileList[0];
    console.log(file);
    this.setState({
      fileName: file.name,
      file: file,
    });
  };

  handleRemove = () => {
    this.setState({
      fileName: "",
      file: null,
    });
  };

  handleCancel = () => {
    this.props.updateReminder(null);
    history.push("/debiteur")
  };

  handleChange = (e) =>{
    e.target.value = "4565"
  }

  onSend = async (data) => {
    const request = await this.props.payWithBridge(data);
    if (request) {
      console.log("REQUESTSUCCESS", request);
    } else {
      console.log("Error: ", request);
    }
  };

  render() {
    const { switchState, items } = this.state;
    const { reminder, loading } = this.props.espaceDebiteur;


    return (
      <StyleWrapper>
        <HomeNavbar />
        <HomeLoginModal />
        <HomeSignupModal />
        <div className="espace-debiteur">
          <div className="espace-debiteur__img"></div>
          <div className="espace-debiteur__content">
            <div className="reference-block">
              <div className="reference-block__data">
                <Button
                  onClick={this.handleCancel}
                  className="btn-cancel"
                  type="default"
                  size="small"
                  shape="circle"
                  icon={<CloseOutlined />}
                ></Button>
                <p>
                  <strong>Réferénce : {reminder?.reference}</strong>
                  <br />
                  <strong> {reminder?.companyName} </strong>
                  <br />
                  <strong>
                    <a href={reminder?.documentUrl} target="_blank" download={true}>
                      <span className="reference-block__data--green-text">
                        {courrierLabels[reminder?.documentType]}
                      </span>
                    </a>
                    {" "} du {" "}  
                    {moment(reminder?.createdAt).format("DD/MM/YYYY")}
                  </strong>
                  <br />
                  <strong>{(reminder?.mainBalance + reminder?.penalties).toFixed(2)} €</strong>
                  <br />
                  <strong>
                    Télécharger les factures{" "}
                    <Button onClick={() => this.props.downloadFactures(reminder?.pdfFactures)} style={{marginLeft:'-6px',border:'0px solid',background:'transparent',color:colors.primaryGreen}} size="small"><strong>ici</strong></Button>
                  </strong>
                </p>
                <div className="reference-block-pay_with_bridge">
                  <Button loading={loading} onClick={() => this.onSend(reminder)} type="primary">
                    Virement instantané
                  </Button>
                  <p> Powered by </p>
                  <a href="https://bridgeapi.io/">
                    <img
                      alt="bridge-logo"
                      src={Bridge}
                      title="Bridge Logo"
                      id="main-logo"
                    />
                  </a>
                </div>
                <p className="bridge-text">Payez par virement instantané ou confirmez le statut de relance</p>
              </div>
              <div className="reference-block__options">
                <div className="reference-block__options_tittle">
                  <h2>
                    <strong>Confirmez le statut de la relance</strong>
                  </h2>
                </div>
                <div className="reference-block__options_items">
                  <Form ref={this.optionRef} onFinish={this.onOptionsFinish}>
                    {items.map((item, index) => (
                      <div
                        className="reference-block__options_item"
                        key={index}
                      >
                        <Switch
                          size="small"
                          checked={switchState[index]}
                          onClick={() => this.handleSwitchClick(index)}
                        />
                        <span className="reference-block__options_item--label">
                          <strong>{item.label}</strong>
                        </span>
                        <div className="reference-block__options_item--switch">
                          {switchState[index]
                            ? item.render({
                                handleRemove: this.handleRemove,
                                handleUpload: this.handleUpload,
                                mainBalance: reminder?.mainBalance,
                                fileName: this.state.fileName,
                                handleChange: this.handleChange 
                              })
                            : ""}
                        </div>
                      </div>
                    ))}
                    <div className="reference-block__options_btn">
                      <Button
                        loading={loading}
                        htmlType="submit"
                        type="primary"
                      >
                        Envoyer
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterNavbar />
      </StyleWrapper>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      user: state.user,
      espaceDebiteur: state.espaceDebiteur,
    }),
    {
      reqFileUpload: espaceDebiteurActions.reqFileUpload,
      reqComputeStatus: espaceDebiteurActions.reqComputeStatus,
      updateReminder: espaceDebiteurActions.updateReminder,
      downloadFactures : espaceDebiteurActions.downloadFactures,
      payWithBridge: espaceDebiteurActions.payWithBridge,
    }
  )(ReferenceDossier)
);
