import actions from "./actions";
import { cloneDeep } from "lodash";

const initState = {
  reminders: [],
  remindersIsLoading: false,
};

export default function rootReducer(state = initState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {
    case actions.UPDATE_REMINDERS:
      newState.reminders = action.payload;
      return newState;

    case actions.REMINDERS_IS_LOADING:
      newState.remindersIsLoading = action.payload;
      return newState;

    default:
      return state;
  }
}
