import styled from 'styled-components';
import {colors} from "../../../helpers/appColors";

const StyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px 40px 20px 150px;

  .link{
    padding-top: 8px;
    padding-left: 2px;
  }
  .link-file{
    color: black;
    text-decoration: underline;
    margin-right: 2px;
  }

  .upload-file{
    width:100%;
  }

  #step-menu {
    margin-top: 30px;
    justify-content: center;
  }

  #new-recovery-btn {
    z-index: 1000;
    color: ${colors.primaryGreen};
    background-color: white;
    border: solid 1px ${colors.primaryGreen};
    font-size: 1.4em;
    position: absolute;
    right: 40px;
    top: 30px;
    border-radius: 5px;
    padding: 2px 10px;
  }

  #new-recovery-btn:hover {
    color: white;
    background: ${colors.primaryGreen};
  }

  th {
    font-size: 12px;
  }

  table tr td {
    font-size: 12px;
  }
`;

export default StyleWrapper;
