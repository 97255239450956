const actions = {
  REQ_FETCH_ORDER: 'REQ_FETCH_ORDER',
  UPDATE_ORDER: 'UPDATE_ORDER',
  UPDATE_PDF: 'UPDATE_PDF',
  REQ_FINALIZE_ORDER: 'REQ_FINALIZE_ORDER',
  TOGGLE_ORDER_LOADER: 'TOGGLE_ORDER_LOADER',
  TOGGLE_ORDER_SUCCESS: 'TOGGLE_ORDER_SUCCESS',
  REQ_CREATE_CHECKOUT_SESSION: 'REQ_CREATE_CHECKOUT_SESSION',
  REQ_FETCH_MY_ORDERS: 'REQ_FETCH_MY_ORDERS',
  UPDATE_MY_ORDERS: 'UPDATE_MY_ORDERS',
  REQ_CREATE_CREDIT_ORDER: 'REQ_CREATE_CREDIT_ORDER',

  reqFetchOrder: (orderId) => ({
    type: actions.REQ_FETCH_ORDER,
    payload: orderId
  }),

  updateOrder: (order) => ({
    type: actions.UPDATE_ORDER,
    payload: {order}
  }),

  updatePdf: (documentToMail) => ({
    type: actions.UPDATE_PDF,
    payload: {documentToMail}
  }),

  reqFinalizeOrder: (type, orderId, hash , checkedMandateOption) => ({
    type: actions.REQ_FINALIZE_ORDER,
    payload: {type, orderId, hash , checkedMandateOption }
  }),

  reqCreateCheckoutSession: (orderId,switchChecked) => ({
    type: actions.REQ_CREATE_CHECKOUT_SESSION,
    payload: {orderId,switchChecked}
  }),

  reqFetchMyOrders: () => ({
    type: actions.REQ_FETCH_MY_ORDERS,
  }),

  updateMyOrders: (myOrders) => ({
    type: actions.UPDATE_MY_ORDERS,
    payload: {myOrders}
  }),

  toggleLoader: (isLoading) => ({
    type: actions.TOGGLE_ORDER_LOADER,
    payload: isLoading
  }),

  toggleOrderSuccess: (isSuccess) => ({
    type: actions.TOGGLE_ORDER_SUCCESS,
    payload: isSuccess
  }),

  reqCreateCreditOrder: (prepaidAmount) => ({
    type: actions.REQ_CREATE_CREDIT_ORDER,
    payload: prepaidAmount
  })


};

export default actions;
