import { cloneDeep } from "lodash";
import actions from './actions';

const initState = {
  current:null,
  isLoading: false,
  orderSuccess: false,
  myOrders: [],
  documentToMail:''
};

export default function rootReducer(state = initState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {

    case actions.UPDATE_ORDER:
      const {order} = action.payload;
      newState.current = order;
      return newState;

    case actions.UPDATE_PDF:
      const {documentToMail} = action.payload;
      newState.documentToMail = documentToMail;
      return newState;

    case actions.UPDATE_MY_ORDERS:
      const {myOrders} = action.payload;
      newState.myOrders = myOrders;
      return newState;

    case actions.TOGGLE_ORDER_LOADER:
      newState.isLoading = action.payload;
      return newState;

    case actions.TOGGLE_ORDER_SUCCESS:
      newState.orderSuccess = action.payload;
      return newState;

    default:
      return state;
  }
}
