/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable default-case */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import userActions from "../../../Redux/user/actions";
import reminderActions from "../../../Redux/reminders/actions";
import calculatorActions from "../../../Redux/calculator/actions";
import moment from 'moment';
import _ from "lodash";
import NumberFormat from 'react-number-format';
import { colors } from '../../../helpers/appColors';
import history from "../../../helpers/history";
import mySendingBoxStatus from "../../../helpers/mySendingBoxStatus";
import courrierLabels from "../../../helpers/courrierLabels";
import statusLabel from '../../../helpers/statusLabel';

import { Table, Button, Tooltip, Input, Select, Popconfirm } from 'antd';
import {CheckOutlined,QuestionCircleOutlined, CopyOutlined, DeleteOutlined, UploadOutlined} from '@ant-design/icons';
import { showError, showSuccess } from '../../../helpers/notificationHelper';
import { Link } from 'react-router-dom';
import CustomerHeader from '../Header/CustomerHeader';

const {Option} =Select;

const SelectActionOptions = ["Nouvelle relance", "supprimer", "nouvelle_relance"];



class CustomerReminders extends Component{

  state = {
    searchValue: null,
    selectedRows: [],
    disabledDelete: true,
    disabledSubmit:true,
    selectedOption: SelectActionOptions,
    activeSousMenu: null,
    menuOuvertRemind: false,
  };

  componentDidMount(){
    this.props.fetchReminders();
  }

  handleSubmitAction = () => {
    
    let selectedIds = [];
    for(let option of this.state.selectedRows){
      selectedIds.push(option.key);
    }
    this.props.bulkDeleteReminder(selectedIds);
    this.setState({
      selectedRows: [],
      disabledSubmit: true,
      disabledDelete: true
    });


  };

  handleNextAction = (nextAction, formValues) => {

    this.props.reqFetchMyCompanies();

    const data = _.cloneDeep(formValues);
    let path;
    data.step = 3;
    switch (nextAction){
      case 'unpaid_notice':
        path = '/amiable';
        data.submitType = 'unpaid_notice';
        data.unpaidNoticeGender = data.reminderGender
        data.unpaidNoticeCompanyName = data.reminderCompanyName
        data.unpaidNoticeAddress = data.reminderAddress
        data.unpaidNoticeZip = data.reminderZip
        data.unpaidNoticeCity = data.reminderCity
        delete data.reminderGender
        delete data.reminderCompanyName
        delete data.reminderAddress
        delete data.reminderZip
        delete data.reminderCity
        delete data.reminderHasStickers
        delete data.reminderSelectedSticker
        delete data.reminderDelegatedSending
        break;
      case 'mise_en_demeure':
        path = '/amiable';
        data.submitType = 'mise_en_demeure';
        data.miseEnDemeureGender = data.unpaidNoticeGender
        data.miseEnDemeureCompanyName = data.unpaidNoticeCompanyName
        data.miseEnDemeureAddress = data.unpaidNoticeAddress
        data.miseEnDemeureZip = data.unpaidNoticeZip
        data.miseEnDemeureCity = data.unpaidNoticeCity
        delete data.unpaidNoticeGender
        delete data.unpaidNoticeCompanyName
        delete data.unpaidNoticeAddress
        delete data.unpaidNoticeZip
        delete data.unpaidNoticeCity
        delete data.unpaidNoticeHasStickers
        delete data.unpaidNoticeSelectedSticker
        delete data.unpaidNoticeDelegatedSending
        break;
      case 'mail_direct_recouvrement':
        path = '/contentieux';
        data.submitType = 'mail_direct_recouvrement';
        break;
      case 'courrier_direct_recouvrement':
        path = '/contentieux';
        data.submitType = 'courrier_direct_recouvrement';
        break;
      case 'mise_en_demeure_direct_recouvrement':
        path = '/contentieux';
        data.submitType = 'mise_en_demeure_direct_recouvrement';
        break;
      case 'injonction':
        path = '/judiciaire';
        data.submitType = 'injonction';
        break;
    }

    setTimeout(() => {
      this.props.updateDefaultFormValues(data);
      history.push(path);
    }, 1000)
  };


  handleCopy = () => {

    // Update the selectedOption state (optional, if needed)
    this.setState((prevState) => ({ menuOuvertRemind: !prevState.menuOuvertRemind }));
    
    if(this.state.selectedRows.length>1){
      showError('ONLY_REMINDER')
    }else{
      const id = _.cloneDeep(this.state.selectedRows[0].key);
      const data = _.cloneDeep(this.state.selectedRows[0].formValues);
      data.step = 1;

      if (data.reference===null)  delete data.reference;
      switch (data.submitType){
        case 'reminder':
          delete data.reminderHasStickers
          delete data.reminderSelectedSticker
          delete data.reminderDelegatedSending
          break;
        case 'unpaid_notice':
          delete data.unpaidNoticeHasStickers
          delete data.unpaidNoticeSelectedSticker
          delete data.unpaidNoticeDelegatedSending
          break;
        case 'mise_en_demeure':
          delete data.miseEnDemeureHasStickers
          delete data.miseEnDemeureSelectedSticker
          delete data.miseEnDemeureDelegatedSending
          break;
      }

      for(const key in data){
        if(data[key]==='null') {
            data[key] = null
        }
      }


      const companyId = this.state.selectedRows[0]?.companyId;
      if ( companyId && !data.companyId){
        data.companyId = companyId;
      }
      

     // this.props.reqInvoices({data:data,id:id});
     this.props.updateDefaultFormValues(data);

     const selectedOptionUrl = this.state.selectedOption
    ? `/${this.state.selectedOption}`
    : '/main';

    this.setState({ menuOuvertRemind: !this.state.menuOuvertRemind });

  // Effectuez les opérations nécessaires avant la navigation

  // Naviguez vers l'URL spécifiée
     history.push(selectedOptionUrl)
    } 
  };

  handleOnchange= value =>{
    this.setState({selectedOption: value})
    if(this.state.selectedRows.length>0){
      this.setState({
        disabledSubmit: false,
      })
    }
  }

  render(){

    const columns = [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        render: (text, record, index) => <span>{moment(record.createdAt).format('DD/MM/YYYY')}</span>,
        sorter: (a,b) => a.createdAt < b.createdAt ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Nom / Raison sociale',
        dataIndex: 'companyName',
        sorter: (a,b) => a.companyName < b.companyName ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Principal',
        dataIndex: 'mainBalance',
        render: value => <span>
          <NumberFormat
            displayType={'text'}
            value={value}
            decimalSeparator={','}
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator={' '}
            suffix={'€'}
          />
        </span>,
        sorter: (a,b) => a.mainBalance < b.mainBalance ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Document',
        dataIndex: 'documentType',
        render: (text, record) => {
          return (
            <Button type='default' size={'small'} href={record.documentUrl} disabled={!record.documentUrl} target={'_blank'} rel="noreferrer">
              {courrierLabels[text]}
            </Button>
          )
        },
        sorter: (a,b) => courrierLabels[a.documentType] < courrierLabels[b.documentType] ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Référence',
        dataIndex: 'reference',
        showSorterTooltip: false,
        sorter: (a,b) => {
          const refA = a.reference && !(['reminder', 'unpaid_notice', 'mise_en_demeure'].includes(a.documentType)) ? a.reference : '';
          const refB = b.reference && !(['reminder', 'unpaid_notice', 'mise_en_demeure'].includes(b.documentType))? b.reference : '';
          return refA < refB ? -1:1
        }
      },
      {
        title: 'Statut La Poste',
        dataIndex: 'mailingStatus',
        sorter: (a,b) => {
          if(!a.mailingStatus && b.mailingStatus) return -1;
          return mySendingBoxStatus[a.mailingStatus] < mySendingBoxStatus[b.mailingStatus] ? -1:1
        },
        showSorterTooltip: false,
        render: (text, record, index) => {
          return <div style={{textAlign: 'center'}}>
            {mySendingBoxStatus[text]}
            {
              record.mailingTrackingNumber ? (
                <>
                  <br/>
                  <a target='_blank' href={`http://www.csuivi.courrier.laposte.fr/suivi/index?id=${record.mailingTrackingNumber}`} title='Suivi'>{record.mailingTrackingNumber}</a>
                </>
              ):''
            }
          </div>;
        }
      },
      {
        title: (
          <span>
            Prochaine action préconisée {' '}
            <Tooltip placement="top" title={`Notre algorithme vous suggère de nouvelles relances en fonction des informations enregistrées (montant, ancienneté, action précédente… )`}><QuestionCircleOutlined/></Tooltip>
          </span>
        ),
        showSorterTooltip: false,
        dataIndex: 'nextAction',
        sorter: (a,b) => moment(a.nextActionAt).format('YYYY-MM-DD') < moment(b.nextActionAt).format('YYYY-MM-DD') ? -1:1,
        render: (text, record, index) => {

          const {userInfo} = this.props;

          const disabled = (
            !record.documentUrl
            && userInfo.subscriptionType === 'Free'
            && moment(userInfo.createdAt).add(7, 'days').format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
            && ['Rappel', `Avis d'impayé`, `Mise en demeure`].includes(courrierLabels[record.nextAction])
          )


          return (
            <div>
              {courrierLabels[record.nextAction] && !record.checkedMandateOption && (
                
                <div style={{textAlign:'center'}}>
                  <Tooltip title={disabled ? `Disponible à partir de l'offre starter` : courrierLabels[record.nextAction] }>
                    <Button
                      type='default'
                      style={!disabled ? {
                        backgroundColor: colors.primaryOrange,
                        borderColor: colors.primaryOrange,
                        color: 'white'
                      } : null}
                      size='small'
                      disabled={disabled}
                      onClick={() => this.handleNextAction(text, record.formValues)}
                    >
                      {courrierLabels[record.nextAction]}
                    </Button>
                  </Tooltip>
                  <br/><span
                  style={moment(record.nextActionAt).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? {color: colors.primaryRed} : null}>le {moment(record.nextActionAt).format('DD/MM/YYYY')}</span>
                </div>
              )}
              { courrierLabels[record.nextAction] && record.checkedMandateOption && (
                <div style={{textAlign:'center'}} >
                    Mandat
                </div>
              )
              }
            </div>
          )
        },
      },
      {
        title: 'Débiteur',
        dataIndex : 'orderStatus',
        render : (text,record, index) => {
          return (
            <div style={{textAlign:'center'}}>
              { record.orderStatus && (
                <>
                  {statusLabel[record?.orderStatus?.status]}
                  <br/>
                  <span>le {moment(record?.orderStatus?.createdAt).format('DD/MM/YYYY')}</span>
                </>
              )}
            </div>
          )
        }
      }
    ];

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRows: selectedRows
        })
        if (selectedRows.length > 0 ) {
          if (this.state.selectedOption[2] === "nouvelle_relance") {
            this.setState({
              disabledSubmit: true,
              disabledDelete: false,
            });
          }else{
          this.setState({
            disabledDelete:false,
            disabledSubmit:false,
          });}
        } else {
          if (this.state.selectedOption[2] === "nouvelle_relance") {
            this.setState({
              disabledSubmit: true,
              disabledDelete: true,
            });
          }else{
          this.setState({
            disabledSubmit: true,
            disabledDelete: true,
          });}
        }
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    let data = [];

    this.props.reminders.map((reminder, idx) => {

      if(reminder.type === 'document'){
        data.push({
          key: reminder.id,
          createdAt: reminder.createdAt,
          companyName: reminder.companyName,
          mainBalance: reminder.mainBalance,
          penalties: reminder.penalties,
          documentType: reminder.documentType,
          documentUrl: reminder.documentUrl,
          reference: (reminder.reference === 'null') ? null : reminder.reference,
          mailingStatus: reminder.mailingStatus,
          nextAction: reminder.nextAction,
          nextActionAt: reminder.nextActionAt,
          formValues: reminder.formValues,
          companyId: reminder.companyId,
          mailingTrackingNumber: reminder.mailingTrackingNumber,
          checkedMandateOption : reminder.checkedMandateOption,
          orderStatus : (reminder.orderStatus) ? reminder.orderStatus : null
        })
      }
    });

    if(this.state.searchValue) {
      data = data.filter(el => {
        el.reference = el.reference ? el.reference : '';
        const formattedMailingStatus = mySendingBoxStatus[el.mailingStatus] ? mySendingBoxStatus[el.mailingStatus] : '';
        const formattedLabel = courrierLabels[el.documentType] ? courrierLabels[el.documentType] : '';

        if ( el.companyName.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
          && el.mainBalance.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
          && el.penalties.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
          && formattedLabel.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
          && el.reference.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
          && formattedMailingStatus.toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
        ) {
          return false;
        }else{
          return true;
        }
      });
    }


    return (
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>
            <Select value={this.state.selectedOption} placeholder="Nouvelle relance" style={{width:170}} onChange={this.handleOnchange}>
                <Option value="penalites">Pénalités de retard</Option>
                <Option value="amiable">Amiable</Option>
                <Option value="contentieux">Contentieux</Option>
                <Option value="judiciaire">Judiciaire</Option>
            </Select>
            <Button type={'primary'} disabled={this.state.disabledSubmit} style={{ marginLeft: 5 }} onClick={this.handleCopy}>
                <CheckOutlined />
            </Button>
            <CustomerHeader 
            selectedOption={this.state.selectedOption} menuOuvertRemind={this.state.menuOuvertRemind} onCopyClick={this.handleCopy}/>
                <Popconfirm
                title="Êtes-vous sûr de vouloir supprimer ces lignes ? Cette action est irréversible"
                onConfirm={this.handleSubmitAction}
                onCancel={() => false}
                okText="Oui"
                cancelText="Annuler"
              >
                <Button type={'primary'} disabled={this.state.disabledDelete} shape='circle' style={{ borderRadius: '50%', marginLeft: '12px' }} icon={<DeleteOutlined />}/>
              </Popconfirm> 
          </div>
          <div>
            <Button type={'default'} style={{marginRight: 5}} disabled={ data.length > 0 ? false : true } onClick={()=>this.props.exportToExcelReminders()}><UploadOutlined /> Exporter</Button>
            <Input style={{width: 300, marginBottom: 10}} placeholder='Rechercher' onChange={(e) => this.setState({searchValue: e.target.value})}/>
          </div>
        </div>
        <Table
          size={'small'}
          bordered={true}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
        />
      </div>

    )
  }
}

export default withRouter(connect(
  state => ({
    token: state.user.token,
    userInfo: state.user.info,
    reminders: state.reminders.reminders,
    defaultFormValues: state.calculator.defaultFormValues,
  }),
  {
    fetchUser: userActions.fetchUser,
    confirmUser: userActions.confirmUser,
    fetchReminders: reminderActions.reqFetchReminders,
    reqFetchMyCompanies: calculatorActions.fetchMyCompanies,
    updateDefaultFormValues: calculatorActions.updateDefaultFormValues,
    bulkDeleteReminder: reminderActions.bulkDeleteReminder,
    exportToExcelReminders: reminderActions.exportToExcelReminders,
    reqInvoices : calculatorActions.reqInvoices
  }
)(CustomerReminders));
