import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import orderActions from "../order/actions";
import ApiProvider from "./../../helpers/api";
import * as notificationHelper from "../../helpers/notificationHelper";
import history from "../../helpers/history";

export function* fetchOrder() {

  yield takeLatest(actions.REQ_FETCH_ORDER, function*({payload}) {

    try {

      const response = yield call(ApiProvider.axiosWithToken.get, `/orders/${payload}`);

      const { order,documentToMail } = response.data;
      console.log('documentToMail',documentToMail);
      yield put(actions.updateOrder(order));
      yield put(actions.updatePdf(documentToMail));

    } catch (error) {

      console.error(error);

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* fetchMyOrders() {

  yield takeLatest(actions.REQ_FETCH_MY_ORDERS, function*() {
    try {
      yield put(actions.toggleLoader(true));
      const response = yield call(ApiProvider.axiosWithToken.get, `/orders`);
      const { myOrders } = response.data;
      yield put(actions.updateMyOrders(myOrders));
      yield put(actions.toggleLoader(false));
    } catch (error) {
      console.error(error);
      yield put(actions.toggleLoader(false));
      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* finalizeOrder() {

  yield takeLatest(actions.REQ_FINALIZE_ORDER, function*({payload}) {

    try {

      yield put(actions.toggleLoader(true));

      const {type, orderId, hash , checkedMandateOption} = payload;

      yield call(ApiProvider.axiosWithToken.post, `/orders/finalize`, {
        type,
        orderId,
        hash,
        checkedMandateOption
      });

      yield put(actions.toggleLoader(false));

      yield put(actions.toggleOrderSuccess(true));


    } catch (error) {

      console.error(error);

      yield put(actions.toggleLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}


export function* createCheckoutSession() {

  yield takeLatest(actions.REQ_CREATE_CHECKOUT_SESSION, function*({payload}) {

    try {

      yield put(actions.toggleLoader(true));

      const {orderId,switchChecked} = payload;

      const response = yield call(ApiProvider.axiosWithToken.post, `/orders/create-checkout-session`, {
        orderId,
        switchChecked
      });

      const {sessionUrl} = response.data;

      if(sessionUrl){
        window.location.href = sessionUrl;
      }else{
        yield put(actions.toggleLoader(false));
        notificationHelper.showError('SERVER_ISSUE');
      }


    } catch (error) {

      console.error(error);

      yield put(actions.toggleLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* reqCreateCreditOrder(){
  yield takeLatest(orderActions.REQ_CREATE_CREDIT_ORDER, function*({payload}) {

    try {

      const response = yield call(ApiProvider.axiosWithToken.post,
        "/orders/credit",
        {prepaidAmount: payload});

      const { order } = response.data;

      // Redirect to summary page with order ID
      history.push(`/order/${order.id}`);

      // Summary page fetch order ID and display info if it is the owner

    } catch (error) {

      console.error(error);

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(fetchOrder),
    fork(finalizeOrder),
    fork(createCheckoutSession),
    fork(fetchMyOrders),
    fork(reqCreateCreditOrder)
  ])
}
